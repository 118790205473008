var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.testButClicked)?_c('div',{staticClass:"alert",attrs:{"role":"alert"}},[_vm._v(" Erfolgreich Gespeichert ! ")]):_vm._e()]),_c('b-container',{staticClass:"reflexion"},[_c('b-row',{staticClass:"reflexion-item"},[_c('label',{attrs:{"for":"textfeld-1"}},[_c('b',[_vm._v("Berichten/Reagieren ")])]),_c('b-form-textarea',{class:{
          error: _vm.$v.getReflexionData.berichten_reagieren.$error,
          valid:
            _vm.$v.getReflexionData.berichten_reagieren.$dirty &&
            !_vm.$v.getReflexionData.berichten_reagieren.$invalid,
        },attrs:{"id":"textfeld-1","placeholder":"Wählen Sie aus, worüber Sie reflektieren wollen: Gab es einen Vorfall, der Ihnen besonders positiv im Gedächtnis geblieben ist? Ist ein Problem oder eine Herausforderung aufgetreten? Fassen Sie zusammen, was vorgefallen ist. Warum ist dieser Vorfall für Sie relevant?"},on:{"input":_vm.$v.getReflexionData.berichten_reagieren.$touch},model:{value:(_vm.getReflexionData.berichten_reagieren),callback:function ($$v) {_vm.$set(_vm.getReflexionData, "berichten_reagieren", $$v)},expression:"getReflexionData.berichten_reagieren"}})],1),_c('b-row',{staticClass:"reflexion-item"},[_c('label',{attrs:{"for":"textfeld-2"}},[_c('b',[_vm._v("In Bezug setzen")])]),_c('b-form-textarea',{class:{
          error: _vm.$v.getReflexionData.in_bezug_setzen.$error,
          valid:
            _vm.$v.getReflexionData.in_bezug_setzen.$dirty &&
            !_vm.$v.getReflexionData.in_bezug_setzen.$invalid,
        },attrs:{"id":"textfeld-2","placeholder":"Setzen Sie den Vorfall bzw. das Problem mit Ihren eigenen Kenntnissen, Fertigkeiten, Erfahrung oder fachlichem Wissen in Verbindung. Ist Ihnen ein ähnlicher Vorfall schon einmal begegnet? Waren die Umstände gleich oder unterschiedlich? Haben Sie die Fähigkeiten und das Wissen, um damit umzugehen? Erläutern Sie."},on:{"input":_vm.$v.getReflexionData.in_bezug_setzen.$touch},model:{value:(_vm.getReflexionData.in_bezug_setzen),callback:function ($$v) {_vm.$set(_vm.getReflexionData, "in_bezug_setzen", $$v)},expression:"getReflexionData.in_bezug_setzen"}})],1),_c('b-row',{staticClass:"reflexion-item"},[_c('label',{attrs:{"for":"textfeld-3"}},[_c('b',[_vm._v("Schlussfolgern ")])]),_c('b-form-textarea',{class:{
          error: _vm.$v.getReflexionData.schlussfolgern.$error,
          valid:
            _vm.$v.getReflexionData.schlussfolgern.$dirty &&
            !_vm.$v.getReflexionData.schlussfolgern.$invalid,
        },attrs:{"id":"textfeld-3","placeholder":"Beleuchten Sie mögliche Ursachen oder Erklärungen für den Vorfall bzw. das Problem. Welche Faktoren waren ausschlaggebend? Setzen Sie Ihre Überlegungen ggf. mit relevanten Theorien oder Wissen in Verbindung und berücksichtigen Sie verschiedene Perspektiven."},on:{"input":_vm.$v.getReflexionData.schlussfolgern.$touch},model:{value:(_vm.getReflexionData.schlussfolgern),callback:function ($$v) {_vm.$set(_vm.getReflexionData, "schlussfolgern", $$v)},expression:"getReflexionData.schlussfolgern"}})],1),_c('b-row',{staticClass:"reflexion-item"},[_c('label',{attrs:{"for":"textfeld-4"}},[_c('b',[_vm._v("Rekonstruieren")])]),_c('b-form-textarea',{class:{
          error: _vm.$v.getReflexionData.rekonstruieren.$error,
          valid:
            _vm.$v.getReflexionData.rekonstruieren.$dirty &&
            !_vm.$v.getReflexionData.rekonstruieren.$invalid,
        },attrs:{"id":"textfeld-4","placeholder":"Rekonstruieren Sie zukünftiges Handeln in Bezug auf den Vorfall bzw. das Problem. Wie würden Sie beim nächsten Mal vorgehen? Welche Ansätze könnten funktionieren und wieso? Gibt es verschiedene Optionen, aus denen Sie wählen können? Welches Ergebnis erwarten Sie?"},on:{"input":_vm.$v.getReflexionData.rekonstruieren.$touch},model:{value:(_vm.getReflexionData.rekonstruieren),callback:function ($$v) {_vm.$set(_vm.getReflexionData, "rekonstruieren", $$v)},expression:"getReflexionData.rekonstruieren"}})],1),_c('b-row',{staticClass:"buttonGroup"},[_c('b-button',{attrs:{"size":"sm","to":{
          name: 'Home',
          params: {
            project_id: this.$route.params.project_id,
          },
        }}},[_vm._v("Dashboard")]),_c('b-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.saveReflexion()}}},[_vm._v("Speichern ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }