var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[(_vm.user.role != 'lecturer')?_c('b-row',{staticClass:"table-container table table-striped"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.testButClicked)?_c('div',{staticClass:"alert",attrs:{"role":"alert"}},[_vm._v(" Erfolgreich gespeichert! ")]):_vm._e()]),_c('table',{staticClass:"table table-borderless table-sm table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Persönliche Daten:")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(_vm.userData.fullname))])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Matrikelnummer")]),_c('td',[_vm._v(_vm._s(_vm.userData.matrikelnummer))])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("E-Mail")]),_c('td',[_vm._v(_vm._s(_vm.userData.mail))])]),_c('thead',[_c('tr',[_c('th',{staticClass:"table-header"},[_vm._v("Profildaten:")])])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Studiengang")]),_c('td',[_c('b-form-input',{class:{
                error: _vm.$v.profile.studiengang.$error,
                valid:
                  _vm.$v.profile.studiengang.$dirty &&
                  !_vm.$v.profile.studiengang.$invalid,
              },attrs:{"id":"input-1"},on:{"input":_vm.$v.profile.studiengang.$touch},model:{value:(_vm.memberProfile.studiengang),callback:function ($$v) {_vm.$set(_vm.memberProfile, "studiengang", $$v)},expression:"memberProfile.studiengang"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Durchgeführte Literaturreviews")]),_c('td',[_c('b-form-input',{class:{
                error: _vm.$v.profile.anzahlLiteraturreviews.$error,
                valid:
                  _vm.$v.profile.anzahlLiteraturreviews.$dirty &&
                  !_vm.$v.profile.anzahlLiteraturreviews.$invalid,
              },attrs:{"id":"input-2"},on:{"input":_vm.$v.profile.anzahlLiteraturreviews.$touch},model:{value:(_vm.memberProfile.anzahlLiteraturreviews),callback:function ($$v) {_vm.$set(_vm.memberProfile, "anzahlLiteraturreviews", $$v)},expression:"memberProfile.anzahlLiteraturreviews"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("E-Mail im Profil anzeigen?")]),_c('td',[_c('b-form-checkbox',{attrs:{"name":"checkbox-email"},on:{"input":function($event){return _vm.updateEmailCheckbox()}},model:{value:(_vm.memberProfile.showEmail),callback:function ($$v) {_vm.$set(_vm.memberProfile, "showEmail", $$v)},expression:"memberProfile.showEmail"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Ich kenne mich in den folgenden... aus:")]),_c('td')]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Datenbanken & Suchmaschinen")]),_c('td',[_c('span',[_vm._v("z.B. AISeL, IEEE, Google Scholar, Web of Science, ...")]),_c('b-form-input',{class:{
                error: _vm.$v.profile.datenbanken.$error,
                valid:
                  _vm.$v.profile.datenbanken.$dirty &&
                  !_vm.$v.profile.datenbanken.$invalid,
              },attrs:{"id":"input-3"},on:{"input":_vm.$v.profile.datenbanken.$touch},model:{value:(_vm.memberProfile.datenbanken),callback:function ($$v) {_vm.$set(_vm.memberProfile, "datenbanken", $$v)},expression:"memberProfile.datenbanken"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Referenzmanagement-Tools")]),_c('td',[_c('span',[_vm._v(" z.B. Citavi, Mendeley, Endnote, ... ")]),_c('b-form-input',{class:{
                error: _vm.$v.profile.referenztool.$error,
                valid:
                  _vm.$v.profile.referenztool.$dirty &&
                  !_vm.$v.profile.referenztool.$invalid,
              },attrs:{"id":"input-4"},on:{"input":_vm.$v.profile.referenztool.$touch},model:{value:(_vm.memberProfile.referenztool),callback:function ($$v) {_vm.$set(_vm.memberProfile, "referenztool", $$v)},expression:"memberProfile.referenztool"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Analysetools")]),_c('td',[_c('span',[_vm._v(" z.B. Excel, MAXQDA, NVivo, R, ... ")]),_c('b-form-input',{class:{
                error: _vm.$v.profile.analysetool.$error,
                valid:
                  _vm.$v.profile.analysetool.$dirty &&
                  !_vm.$v.profile.analysetool.$invalid,
              },attrs:{"id":"input-5"},on:{"input":_vm.$v.profile.analysetool.$touch},model:{value:(_vm.memberProfile.analysetool),callback:function ($$v) {_vm.$set(_vm.memberProfile, "analysetool", $$v)},expression:"memberProfile.analysetool"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Profilbild")]),_c('td',{staticClass:"profilbild-content"},[_c('picture-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],ref:"pictureInput",attrs:{"title":"Klicken Sie bitte hier drauf, um ein Bild hochzuladen!","width":"100","height":"100","accept":"image/jpeg,image/png","buttonClass":"btn btn-test","hideChangeButton":true,"customStrings":{
                upload: '<h1>Bild ändern</h1>',
                drag: 'Bild hochladen',
              }},on:{"change":_vm.onFileChanged},model:{value:(_vm.profilbild),callback:function ($$v) {_vm.profilbild=$$v},expression:"profilbild"}})],1)])])]),_c('b-row',[_c('b-row',{staticClass:"buttons"},[_c('b-button',{on:{"click":function($event){return _vm.updateProfile()}}},[_vm._v("Änderungen speichern")]),_c('b-button',{on:{"click":function($event){return _vm.cancelUpdate()}}},[_vm._v("Änderungen verwerfen")])],1)],1)],1):_vm._e(),(_vm.user.role == 'lecturer')?_c('b-row',{staticClass:"table-container table table-striped"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.testButClicked)?_c('div',{staticClass:"alert",attrs:{"role":"alert"}},[_vm._v(" Erfolgreich gespeichert! ")]):_vm._e()]),_c('table',{staticClass:"table table-borderless table-sm table-hover"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Persönliche Daten:")])])]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Name")]),_c('td',[_vm._v(_vm._s(_vm.userData.fullname))])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("E-Mail")]),_c('td',[_vm._v(_vm._s(_vm.userData.mail))])]),_c('thead',[_c('tr',[_c('th',[_vm._v("Profildaten:")])])]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Abteilung")]),_c('td',[_c('b-form-input',{class:{
                error: _vm.$v.profile.abteilung.$error,
                valid:
                  _vm.$v.profile.abteilung.$dirty &&
                  !_vm.$v.profile.abteilung.$invalid,
              },attrs:{"id":"input-6"},on:{"input":_vm.$v.profile.abteilung.$touch},model:{value:(_vm.memberProfile.abteilung),callback:function ($$v) {_vm.$set(_vm.memberProfile, "abteilung", $$v)},expression:"memberProfile.abteilung"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Telefonnummer")]),_c('td',[_c('b-form-input',{class:{
                error: _vm.$v.profile.telefonnummer.$error,
                valid:
                  _vm.$v.profile.telefonnummer.$dirty &&
                  !_vm.$v.profile.telefonnummer.$invalid,
              },attrs:{"id":"input-7"},on:{"input":_vm.$v.profile.telefonnummer.$touch},model:{value:(_vm.memberProfile.telefonnummer),callback:function ($$v) {_vm.$set(_vm.memberProfile, "telefonnummer", $$v)},expression:"memberProfile.telefonnummer"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("E-Mail im Profil anzeigen?")]),_c('td',[_c('b-form-checkbox',{attrs:{"name":"checkbox-email"},on:{"input":function($event){return _vm.updateEmailCheckbox()}},model:{value:(_vm.memberProfile.showEmail),callback:function ($$v) {_vm.$set(_vm.memberProfile, "showEmail", $$v)},expression:"memberProfile.showEmail"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Telefon-Nr. im Profil anzeigen?")]),_c('td',[_c('b-form-checkbox',{attrs:{"name":"checkbox-phone"},on:{"input":function($event){return _vm.updatePhoneNumberCheckbox()}},model:{value:(_vm.memberProfile.showPhoneNumber),callback:function ($$v) {_vm.$set(_vm.memberProfile, "showPhoneNumber", $$v)},expression:"memberProfile.showPhoneNumber"}})],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v("Profilbild")]),_c('td',{staticClass:"profilbild-content"},[_c('picture-input',{ref:"pictureInput",attrs:{"width":"100","height":"100","accept":"image/jpeg,image/png","buttonClass":"btn btn-test","hideChangeButton":true,"customStrings":{
                upload: '<h1>Bild ändern</h1>',
                drag: 'Bild hochladen',
              }},on:{"change":_vm.onFileChanged},model:{value:(_vm.profilbild),callback:function ($$v) {_vm.profilbild=$$v},expression:"profilbild"}})],1)])])]),_c('b-row',[_c('b-row',{staticClass:"buttons"},[_c('b-button',{on:{"click":function($event){return _vm.updateProfile()}}},[_vm._v("Änderungen speichern")]),_c('b-button',{on:{"click":function($event){return _vm.cancelUpdate()}}},[_vm._v("Änderungen verwerfen")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }