var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"navbar-top",attrs:{"toggleable":"lg","variant":"secondary","type":"dark","sticky":""}},[_c('b-navbar-toggle',{attrs:{"target":"nav-text-collapse"}}),(_vm.inProjectList || _vm.inEinstellungen || _vm.inProfil || _vm.inProjectSearch)?_c('b-navbar-brand',[_vm._v("eCLR Tool")]):_c('b-navbar-brand',[_vm._v(_vm._s(_vm._f("truncate")(_vm.currentProject.title,19)))]),_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":"/"}},[_c('b-icon',{attrs:{"icon":"house-fill","to":"/"}},[_vm._v("Startseite ")])],1)],1),_c('b-collapse',{attrs:{"id":"nav-text-collapse","variant":"secondary","is-nav":""}},[_c('b-navbar-nav',[(
          (_vm.inProjectList || _vm.inEinstellungen || _vm.inProfil || _vm.inProjectSearch) &&
          _vm.isUserStudent
        )?_c('b-nav-item',{attrs:{"to":{
          name: 'StudentProfile',
          params: {
            user_internal_uid: _vm.user.uid,
          },
        }}},[_vm._v("Profil")]):_vm._e(),(
          (_vm.inProjectList || _vm.inEinstellungen || _vm.inProfil || _vm.inProjectSearch) &&
          !_vm.isUserStudent
        )?_c('b-nav-item',{attrs:{"to":{
          name: 'LecturerProfile',
          params: {
            user_internal_uid: _vm.user.uid,
          },
        }}},[_vm._v("Profil")]):_vm._e()],1),(!_vm.inProjectList & !(this.$route.params.project_id == undefined))?[_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":{
            name: 'Projektbeschreibung',
            params: {
              project_id: this.$route.params.project_id,
            },
          }}},[_vm._v("Projektbeschreibung")])],1),_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":{
            name: 'Home',
            params: {
              project_id: this.$route.params.project_id,
            },
          }}},[_vm._v("Dashboard")])],1),_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":{
            name: 'Forum',
            params: {
              project_id: this.$route.params.project_id,
            },
          }}},[_vm._v("Projektforum")])],1),(_vm.user.role != 'lecturer')?_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":{
            name: 'Groupmanagement',
            params: {
              project_id: this.$route.params.project_id,
            },
          }}},[_vm._v("Gruppenmanagement")])],1):_vm._e()]:_vm._e(),(_vm.inProjectList || _vm.inEinstellungen || _vm.inProfil || _vm.inProjectSearch)?_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":{
          name: 'Einstellungen',
        }}},[_vm._v("Einstellungen")])],1):_vm._e(),(_vm.inProjectList || _vm.inEinstellungen || _vm.inProfil)?_c('b-navbar-nav',[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-link',{attrs:{"to":{
              name: 'ProjectSearch',
              params: { keyword: _vm.keyword },
            }}},[_c('b-icon',{attrs:{"icon":"search"}})],1)],1),_c('b-form-input',{attrs:{"type":"search","placeholder":"Alle Projekte durchsuchen"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1)],1):_vm._e()],2),_c('b-navbar-nav',[_c('Postfach')],1),_c('b-navbar-nav',[_c('b-button',{on:{"click":function($event){return _vm.logout()}}},[_c('b-icon',{attrs:{"icon":"box-arrow-right"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }