var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-background"},[_c('Gdpr',{on:{"gdprAccepted":_vm.enableButtons}}),_c('div',{staticClass:"container"},[_c('b-card',{staticClass:"container-form"},[_c('b-form-group',[_c('b-tabs',{model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',{attrs:{"title":"Login"}},[_c('div',{staticClass:"login-header"},[_c('h5',[_vm._v("Melde dich hier mit deinem Uni-Account an")]),_c('p',[_vm._v(" Wenn du dich noch nicht registriert hast, bitte registriere dich mit deinem Uni-Account ")])]),_c('table',[_c('tr',[_c('td',[_c('label',{attrs:{"for":"zugangsKennung"}},[_vm._v("Zugangskennung")])]),_c('td',[_c('b-form-input',{class:{
                      error: _vm.$v.zugangsKennung.$error,
                      valid:
                        _vm.$v.zugangsKennung.$dirty &&
                        !_vm.$v.zugangsKennung.$invalid,
                    },attrs:{"id":"zugangsKennung","placeholder":""},on:{"input":_vm.$v.zugangsKennung.$touch},model:{value:(_vm.zugangsKennung),callback:function ($$v) {_vm.zugangsKennung=$$v},expression:"zugangsKennung"}})],1)]),_c('tr',[_c('td',[_c('label',{attrs:{"for":"password"}},[_vm._v("Passwort")])]),_c('td',[_c('b-form-input',{class:{
                      error: _vm.$v.passwort.$error,
                      valid: _vm.$v.passwort.$dirty && !_vm.$v.passwort.$invalid,
                    },attrs:{"type":"password","id":"password","placeholder":""},on:{"input":_vm.$v.passwort.$touch},model:{value:(_vm.passwort),callback:function ($$v) {_vm.passwort=$$v},expression:"passwort"}})],1)])]),_c('b-button',{attrs:{"disabled":!_vm.gdprAccepted},on:{"click":function($event){return _vm.login()}}},[_vm._v("Login")])],1),_c('b-tab',{attrs:{"title":"Registrierung"}},[_c('div',{staticClass:"registrierung-header"},[_c('h5',[_vm._v("Registriere dich hier mit deinem Uni-Account")]),_c('p',[_vm._v(" Falls du Hilfe benötigst, wende dich an maren.stadtlaender@uni-hildesheim.de ")])]),_c('table',[_c('tr',[_c('td',[_c('label',{attrs:{"for":"checkbox-is-lecturer"}},[_vm._v("Ich bin ein Dozent")])]),_c('td',[_c('b-form-checkbox',{staticStyle:{"margin-left":"0.5rem"},attrs:{"name":"checkbox-is-lecturer"},model:{value:(_vm.getIsLecturer),callback:function ($$v) {_vm.getIsLecturer=$$v},expression:"getIsLecturer"}})],1)]),_c('tr',[_c('td',[_c('label',{attrs:{"for":"zugangsKennung"}},[_vm._v("Zugangskennung")])]),_c('td',[_c('b-form-input',{class:{
                      error: _vm.$v.registrierungsKennung.$error,
                      valid:
                        _vm.$v.registrierungsKennung.$dirty &&
                        !_vm.$v.registrierungsKennung.$invalid,
                    },attrs:{"id":"zugangskennung2","type":"text","placeholder":""},on:{"input":_vm.$v.registrierungsKennung.$touch},model:{value:(_vm.registrierungsKennung),callback:function ($$v) {_vm.registrierungsKennung=$$v},expression:"registrierungsKennung"}})],1)]),_c('tr',[_c('td',[_c('label',{attrs:{"for":"password"}},[_vm._v("Passwort")])]),_c('td',[_c('b-form-input',{class:{
                      error: _vm.$v.registrierungsPasswort.$error,
                      valid:
                        _vm.$v.registrierungsPasswort.$dirty &&
                        !_vm.$v.registrierungsPasswort.$invalid,
                    },attrs:{"type":"password","id":"password2","placeholder":""},on:{"input":_vm.$v.registrierungsPasswort.$touch},model:{value:(_vm.registrierungsPasswort),callback:function ($$v) {_vm.registrierungsPasswort=$$v},expression:"registrierungsPasswort"}})],1)]),_c('tr',[_c('td',[_c('label',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 mt-1",attrs:{"title":"Studenten geben bitte ihre Matrikelnummer mit an","for":"Matrikelnummer"}},[_vm._v("Matrikelnummer")])]),_c('td',[_c('b-form-input',{class:{
                      error: _vm.$v.matrikelnummer.$error,
                      valid:
                        _vm.$v.matrikelnummer.$dirty &&
                        !_vm.$v.matrikelnummer.$invalid,
                    },attrs:{"disabled":_vm.getIsLecturer,"id":"matrikelnummer","placeholder":""},on:{"input":_vm.$v.matrikelnummer.$touch},model:{value:(_vm.matrikelnummer),callback:function ($$v) {_vm.matrikelnummer=$$v},expression:"matrikelnummer"}})],1)])]),_c('b-button',{attrs:{"disabled":!_vm.gdprAccepted},on:{"click":function($event){return _vm.registrieren()}}},[_vm._v("Registrieren")])],1)],1)],1)],1),_c('v-footer',{attrs:{"padless":"","absolute":""}},[_c('v-card',{staticClass:"flat tile text-center",attrs:{"color":"#6c757d"}},[_c('v-card-title',[_vm._v(" eCLR Tool - Das Tool für kollaborative Literaturreviews in der Lehre ")]),_c('v-card-subtitle',[_vm._v(" Dieses OpenSource-Projekt wurde im Rahmen der Ausschreibung \"Qualität Plus\" des MWK Niedersachsen erstellt. Näheres dazu finden Sie "),_c('a',{attrs:{"href":"https://www.uni-hildesheim.de/fb4/institute/bwl/informationssysteme-und-unternehmensmodellierung/projekte/qualitaet-plus/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("hier")]),_vm._v(". ")]),_c('v-divider'),_c('v-card-text',{staticClass:"pb-3"},[_c('img',{attrs:{"src":require("../assets/logo.svg"),"width":"24px","height":"24px"}}),_c('a',{attrs:{"href":"https://www.uni-hildesheim.de/impressum/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" Universität Hildesheim ")]),_vm._v(" - "+_vm._s(new Date().getFullYear())+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }