var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.getLoadingStatus)?_c('div',[_c('b-card',{attrs:{"header-tag":"header","footer-tag":"footer"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v(" "+_vm._s(_vm.currentProject.title)+" ")])])]},proxy:true}],null,false,1609881749)},[_c('b-card-text',[_vm._l((_vm.currentProject.gruppenmitglieder),function(mitglied){return _c('div',{key:mitglied.userid},[_c('b-row',[_c('b-col',[_c('p',[_vm._v("Gruppenmitglied")])]),_c('b-col',[_c('b-nav-item',{attrs:{"to":{
                name: 'StudentProfile',
                params: {
                  user_internal_uid: mitglied.internal_uid,
                },
              }}},[_c('p',{staticClass:"mitglied"},[_c('b',[_vm._v(" "+_vm._s(mitglied.username)+" ")])])])],1),_c('b-col',[((_vm.userData.uuid != mitglied.userid) & _vm.isUserAdmin)?_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.deleteMember(mitglied)}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e(),((_vm.userData.uuid != mitglied.userid) & _vm.isUserAdmin)?_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.giveAdminRights(mitglied)}}},[_c('b-icon',{attrs:{"icon":"person-check-fill"}})],1):_vm._e(),_c('br')],1)],1)],1)}),_vm._l((_vm.currentProjectGroupAdmins),function(admin){return _c('div',{key:admin.userid},[_c('b-row',[_c('b-col',[_c('p',[_vm._v("Gruppenadministrator")])]),_c('b-col',[_c('b-nav-item',{attrs:{"to":{
                name: 'StudentProfile',
                params: {
                  user_internal_uid: admin.internal_uid,
                },
              }}},[_c('p',{staticClass:"admin"},[_c('b',[_vm._v(_vm._s(admin.username))])])])],1),_c('b-col',[((_vm.userData.uuid != admin.userid) & _vm.isUserAdmin)?_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.deleteAdmin(admin)}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e(),_c('br')],1)],1)],1)}),_c('br'),_c('b-row',{staticClass:"groupmanagement-buttons"},[(_vm.isUserAdmin)?_c('b-button',{attrs:{"to":{ name: 'NewMember' },"tag":"div"}},[_vm._v(" Neues Mitglied hinzufügen ")]):_vm._e(),(_vm.isUserAdmin)?_c('b-button',{on:{"click":function($event){return _vm.$bvModal.show('admin-removes-rights')}}},[_vm._v("Admin-Rechte abgeben ")]):_vm._e(),_c('b-modal',{attrs:{"id":"admin-removes-rights","title":"Bist du dir sicher?","cancel-title":"Abbrechen"},on:{"ok":_vm.removeOwnAdminRights}},[_c('p',[_vm._v(" Du kannst dieses Projekt nicht mehr verwalten, wenn du deine Administrationsrechte aufgibst! ")])]),_c('b-button',{on:{"click":function($event){return _vm.$bvModal.show('student-leave-group')}}},[_vm._v("Gruppe verlassen ")])],1),_c('b-modal',{attrs:{"id":"student-leave-group","title":"Bist du dir sicher?","cancel-title":"Abbrechen"},on:{"ok":_vm.leaveGroup}},[_c('p',[_vm._v(" Du kannst nicht mehr auf das Projekt zugreifen, wenn du das Projekt verlässt! ")])])],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }